<template>
  <div class="trends-container">
    <div class="inner">
      <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>课程管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="type-bar">
        <div class="t-list">
          <div class="t-type">状态:</div>
          <div
            :class="dayTypeIndex === index ? 't-item-active' : 't-item'"
            v-for="(item, index) in dayType"
            :key="index"
            @click="changeDayState(item, index)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="t-list">
          <div class="t-type-40">指定日期：</div>
          <el-date-picker
            class="date-picker"
            v-model="date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="onfirmDate"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>
      </div>
      <div class="tab-bar">
        <div class="t-tab">
          <div
            :class="['t-item', { 't-current': index === tabIndex }]"
            v-for="(item, index) in tabList"
            :key="index"
            @click="changeTab(index)"
          >
            {{ item }}
            <div class="t-line"></div>
          </div>
        </div>
      </div>
      <el-table class="table" :data="newStudentList" style="width: 100%" v-show="tabIndex == 0">
        <el-table-column prop="name" label="学生姓名" width="180"></el-table-column>
        <el-table-column prop="nickName" label="昵称" width="180"></el-table-column>
        <el-table-column prop="mobileNo" label="手机号"></el-table-column>
        <el-table-column prop="sex" label="性别"></el-table-column>
        <el-table-column prop="courseName" label="课程名称"></el-table-column>
        <el-table-column prop="courseMode" label="课程类型"></el-table-column>
        <el-table-column prop="createTime" label="报名日期"></el-table-column>
      </el-table>
      <div ref="charts3" class="t-charts3" v-show="tabIndex == 1"></div>
      <el-pagination
        class="page mt50"
        prev-text="上一页"
        next-text="下一页"
        background
        layout="prev, pager, next"
        :total="totalPages"
        :page-size.sync="pageSize"
        @current-change="currentChange"
        :current-page.sync="pageIndex"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
  name: '',
  data() {
    return {
      dayTypeIndex: 0,
      dayType: [
        { name: '7天', value: '7' },
        { name: '近一个月', value: '30' },
        { name: '三个月', value: '90' }
      ],
      // 总条数
      totalPages: 0,
      // 每页条数
      pageSize: 10,
      // 当前页码
      pageIndex: 1,
      date: [],
      endDay: dayjs(this.curDate)
        .add(7, 'day')
        .format('YYYY-MM-DD'),
      startDay: this.$.date.format(new Date(), 'yyyy-MM-dd'),
      newStudentList: [],
      studentList: [],
      // 当前年月日
      curDate: this.$.date.format(new Date(), 'yyyy-MM-dd'),
      tabIndex: 0,
      tabList: ['统计表', '趋势图'],
      studentStatDay: [],
      studentNumList: []
    };
  },
  computed: {},
  created() {
    this.getNewStudentList();
    this.getStudentNumStat();
  },
  mounted() {
    // this.mycharts3(this.studentStatDay, this.studentNumList);
  },
  watch: {},
  methods: {
    // 当前页变化时执行
    currentChange(e) {
      this.pageIndex = e;
      this.getNewStudentList();
    },
    // 更改日期状态
    changeDayState(item, index) {
      this.pageIndex = 1;
      this.dayTypeIndex = index;
      this.startDay = this.curDate;
      if (item.value == '7') {
        this.endDay = dayjs(this.curDate)
          .add(7, 'day')
          .format('YYYY-MM-DD');
      } else if (item.value == '30') {
        this.endDay = dayjs(this.curDate)
          .add(1, 'month')
          .format('YYYY-MM-DD');
      } else if (item.value == '90') {
        this.endDay = dayjs(this.curDate)
          .add(3, 'month')
          .format('YYYY-MM-DD');
      }
    },
    // 确认日期
    onfirmDate() {
      console.log(this.date);
      this.pageIndex = 1;
      if (this.date) {
        this.startDay = this.date[0];
        this.endDay = this.date[1];
      } else {
        this.startDay = '';
        this.endDay = '';
      }
      if (this.tabIndex == 0) {
        this.getNewStudentList();
      } else if (this.tabIndex == 1) {
        this.getStudentNumStat();
      }
    },
    // 获取给定的时间范围新增的学员列表
    getNewStudentList() {
      this.post(
        `/biz/teacher/student/trend/newStudentList?pageNum=${this.pageIndex}&pageSize=${this.pageSize}`,
        { startDay: this.startDay, endDay: this.endDay },
        e => {
          if (e.code == 200) {
            this.newStudentList = e.rows;
            this.totalPages = e.total;
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
    },
    // 更改 tab
    changeTab(index) {
      this.tabIndex = index;
      this.pageIndex = 1;
      if (this.tabIndex == 0) {
        this.getNewStudentList();
      } else if (this.tabIndex == 1) {
        this.getStudentNumStat();
      }
    },
    mycharts3(x, y) {
      let myChart = this.$echarts.init(this.$refs.charts3, 'macarons');
      myChart.setOption({
        title: {
          text: '近一个月学员趋势图',
          textStyle: {
            width: 150
          }
        },
        color: ['rgb(0, 122, 255)', 'rgb(255, 204, 0)', 'rgb(88, 86, 214)', 'rgb(20, 204, 204)'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            crossStyle: {
              color: '#999'
            }
          },
          position: function(pt) {
            //提示框的位置
            return [pt[0], 30];
          }
        },
        grid: {
          //图表和父盒子之间的距离
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '17%',
          containLabel: true
        },
        legend: {},
        xAxis: {
          //x轴
          type: 'category',
          // boundaryGap: false,
          data: x,
          axisLabel: {
            interval: 0,
            textStyle: {
              color: '#89898C',
              fontSize: 10
            },
            margin: 8
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgb(2,121,253)'
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            //x轴的坐标文字
            show: true,
            textStyle: {
              color: '#89898C' //文字的颜色
            }
          },
          max: 100,
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgb(2,121,253)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            //坐标在grid区域的分割线
            lineStyle: {
              //设置分割线的样式(图表横线颜色)
              color: ['#153a8a']
            }
          }
        },
        series: [
          {
            name: '学员增长数量',
            type: 'line', //柱状图
            data: y,
            barWidth: '8%' //柱状体的宽度
          }
        ]
      });
      //让图表自适应
      window.addEventListener('resize', function() {
        myChart.resize(); // myChart 是实例对象
      });
    },
    // 给定的时间范围统计学员增长数量
    getStudentNumStat() {
      this.post(
        '/biz/teacher/student/trend/studentNumStat',
        { startDay: this.startDay, endDay: this.endDay },
        e => {
          if (e.code == 200) {
            this.studentList = e.data;
            this.studentList.forEach(item => {
              this.studentStatDay.push(item.courseDay);
              this.studentNumList.push(item.studentNum);
            });
            this.mycharts3(this.studentStatDay, this.studentNumList);
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
    }
  },
  components: {}
};
</script>

<style></style>
